// TODO: Refactor ConsumerContainer to function component and memoize context values
/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { compose } from '@shakacode/recompose';
import { getApolloClient } from '~/lazy_apollo';
import { createEvent, initAnalytics } from '~/utils/eventable';
import { PopmenuConfigContext } from '~/utils/withPopmenuConfig';
import { withIntl } from '../utils/withIntl';
import { openVipV2Modal } from '../shared/ModalActions';
import { captureSentryMessage } from '../utils/sentry';
import { getParam, removeHostName } from '../utils/urls';
import { prefetchPopularPagesData, saveAdditionalStorageKeyParams } from '../utils/prefetchGraphQLQueries';
import { RestaurantContextProvider } from '../utils/withRestaurant';
import { popmenuConfigShape } from '../utils/popmenuConfigShape';
import { ThemeContext, themeShape } from '../utils/withTheme';
import { SnackbarProvider } from '../utils/withSnackbar';
import { ThemeProvider } from '../utils/withStyles';
import { getThemeStyleTags } from '../utils/themes';
import {
  setMenuItemCartIsScheduled,
  setMenuItemCartLocationId, setMenuItemCartScheduledAt,
  setMenuItemCartType, setMenuItemCartFulfillmentType,
  setMenuItemCartId,
} from '../shared/MenuItemCartActions';
import { setConsumerActiveSection, setIsPreview } from '../shared/ConsumerActions';
import FeatureFlagsContextProvider from '../utils/featureFlagsContext';

import {
  BlogPostPage,
  BlogPostIndexPage,
  CalendarEventPage,
  CurbsideArrive,
  DineInOrderingPage,
  FeedbackPage,
  FeedbackThankyouPage,
  MenuItemPage,
  MenuItemCartReviewPage,
  MenuPage,
  OfferPage,
  OrderingEventPage,
  OrderingEventOrderPage,
  OrderingPreviewPage,
  ConfirmationPage,
  PreviewPage,
  ResetPage,
  ResetTokenPage,
  ReviewsPage,
  TermsPage,
  WaitlistPage,
  WaitlistLinePage,
  ReservationsPage,
} from './ConsumerContainer.imports-loadable';

import CustomPagePage from './pages/CustomPagePage';
import CustomHomePage from './home/CustomHomePage';

import ActionBar from './action_bar/ActionBar';
import AriaElementGroup from '../shared/AriaElementGroup';
import AriaHiddenFixDialog from '../shared/AriaHiddenFixDialog';
import ConsumerModalContainer from './shared/ConsumerModalContainer';
import CurrentSessionProvider from '../shared/CurrentSessionProvider';
import EventListenerContainer from '../shared/EventListenerContainer';
import ErrorContainer from './shared/ErrorContainer';
import Footer from './shared/Footer/Footer.imports-loadable';
import Loading from '../shared/Loading';
import Nav from './shared/Nav';
import PageChangeHandler from '../shared/PageChangeHandler';
import WindowProvider from '../shared/WindowProvider';
import WindowSizeProvider from '../shared/WindowSizeProvider';

import { visualRegressionsMode } from '../utils/visualRegressionsMode';
import BannerContainer from './BannerContainer';
import { MenuItemsQueryCacheProvider } from './menus/GetItemContext';
import { VirtualizedMenuContextProvider } from './menus/Menu/VirtuosoMenu/VirtualizedMenuContext';
import MultiSectionLazyContainer from '../shared/MultiSectionLazyContainer';
import { processOutboundLinkClick } from './ConsumerContainerUtils';

class ConsumerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      orderingEventOrderPage: 'menu',
    };
    this.mainContentRef = React.createRef();
    this.handleOutboundLinkClick = this.handleOutboundLinkClick.bind(this);
    this.onCheckoutButtonClick = this.onCheckoutButtonClick.bind(this);
    this.onGoToMenuButtonClick = this.onGoToMenuButtonClick.bind(this);
    this.menuItemCartOnCompleteCallback = this.menuItemCartOnCompleteCallback.bind(this);
    // this.handleResize = this.handleResize.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.draftMode) {
      console.warn('[POPMENU] Skipped initializing analytics in draft mode');
      return;
    }
    initAnalytics({
      adminApp: false,
      popmenuConfig: this.props.data.popmenuConfig,
      restaurant: this.props.data.restaurant,
    });
  }

  componentDidMount() {
    // Token included in hash if social sign-in callback failed
    // SignUpModal.componentDidMount handles token
    const socialToken = getParam('facebook_token') || getParam('google_token');
    if (socialToken) {
      this.props.openVipV2Modal();
    }
    if (this.props.draftMode) {
      const createLiveServicePreviewPromise = Promise.all([
        import(/* webpackChunkName: "LivePreviewService" */ './services/LivePreviewService'),
        getApolloClient(),
      ])
        .then(([{ default: LivePreviewService }, client]) => new LivePreviewService(client, this.props.restaurantId, this.props.history));

      // listens for messages from outside of iframe via refreshConsumerPreview
      window.addEventListener('message', ({ data }) => {
        if (data?.isPreview) {
          this.props.setIsPreview();
        } else if (data?.activeSection) {
          this.props.setConsumerActiveSection(data.activeSection);
        } else {
          if (data?.section && data?.scrollTo) {
            this.props.setConsumerActiveSection({ id: data.section });
          }
          createLiveServicePreviewPromise.then(service => service.update(data));
        }
      });
    }
    if (this.props.data && this.props.data.popmenuConfig) {
      console.log(`[POPMENU] Popmenu Version ${this.props.data.popmenuConfig.popmenuVersion}`);
    }

    // Event listener for outbound link clicks
    document.addEventListener('click', this.handleOutboundLinkClick);
  }

  componentDidCatch(err) {
    this.setState({ hasError: true });
    if (window.Sentry) {
      window.sentryCaptureException(err);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutboundLinkClick);
  }

  handleOutboundLinkClick(event) {
    processOutboundLinkClick(event, createEvent, removeHostName);
  }

  onCheckoutButtonClick() {
    this.setState({ orderingEventOrderPage: 'checkout' });
    this.props.history.push('#checkout');
  }

  onGoToMenuButtonClick() {
    this.setState({ orderingEventOrderPage: 'menu' });
    this.props.history.push('#menu');
  }

  menuItemCartOnCompleteCallback(menuItemCart) {
    this.props.setMenuItemCartScheduledAt(menuItemCart.scheduledAt);
    this.props.setMenuItemCartIsScheduled(menuItemCart.isScheduled);
    this.props.setMenuItemCartFulfillmentType(menuItemCart.fulfillmentType);
    this.props.setMenuItemCartLocationId(menuItemCart.location.id);
    this.props.setMenuItemCartId(menuItemCart.id);
    this.props.setMenuItemCartType(menuItemCart.cartType);
  }

  renderRoutes() {
    return (
      <Switch>
        {this.props.data.restaurant.customPages.map((customPage) => {
          if (customPage.isHomePage || !customPage.url) {
            return null;
          }
          return (
            <Route key={customPage.id} path={customPage.url} exact>
              <CustomPagePage />
            </Route>
          );
        })}
        {this.props.data.restaurant.featureSetting.isOrderingDineInEnabled && (
          <Route path="/dine-in">
            <DineInOrderingPage hashRoute={this.props.location.hash ? this.props.location.hash : ''} />
          </Route>
        )}
        {this.props.data.restaurant.featureSetting.isWaitlistEnabled && (
        <Route path="/waitlist/line/:id">
          <WaitlistLinePage
            restaurantName={this.props.data.restaurant.name}
            logoUrl={this.props.data.restaurant.logoUrl}
          />
        </Route>
        )}
        {this.props.data.restaurant.featureSetting.isWaitlistEnabled && this.props.data.restaurant.featureSetting.isWaitlistV2Enabled && (
        <Route path="/waitlist">
          <WaitlistPage restaurant={this.props.data.restaurant} />
        </Route>
        )}
        {this.props.data.restaurant.featureSetting.isReservationsEnabled && (
          <Route path="/reservations">
            <ReservationsPage restaurant={this.props.data.restaurant} />
          </Route>
        )}
        <Route path="/curbside-arrive">
          <CurbsideArrive restaurant={this.props.data.restaurant} />
        </Route>
        <Route path="/menus/:menuSlug">
          <MenuPage />
        </Route>
        <Route path="/items/:dishSlug">
          <MenuItemPage />
        </Route>
        <Route path="/reset/:token">
          <ResetTokenPage />
        </Route>
        <Route path="/reset">
          <ResetPage />
        </Route>
        <Route path="/accessibility">
          <TermsPage restaurant={this.props.data.restaurant} termsType="webAccessibility" />
        </Route>
        <Route path="/offers/:offerCode">
          <OfferPage restaurant={this.props.data.restaurant} />
        </Route>
        <Route path="/reviews/order/:token">
          <MenuItemCartReviewPage restaurant={this.props.data.restaurant} />
        </Route>
        <Route path="/reviews">
          <ReviewsPage />
        </Route>
        <Route path="/posts/:blogPostSlug">
          <BlogPostPage
            firstLocation={this.props.data.restaurant.firstLocation}
            restaurantId={this.props.data.restaurant.id}
            restaurantLogoUrl={this.props.data.restaurant.logoUrl}
            restaurantName={this.props.data.restaurant.name}
            restaurantPhotoUrl={this.props.data.restaurant.photoUrl}
            restaurantUrl={this.props.data.restaurant.popmenuUrl}
            theme={this.props.data.restaurant.theme}
          />
        </Route>
        <Route path="/posts">
          <BlogPostIndexPage
            location={this.props.data.restaurant.firstLocation}
            restaurant={this.props.data.restaurant}
          />
        </Route>
        <Route path="/confirmation/:id">
          <ConfirmationPage restaurant={this.props.data.restaurant} />
        </Route>
        <Route path="/preview-ordering/:location_id/:fulfillment_type">
          <OrderingPreviewPage restaurant={this.props.data.restaurant} />
        </Route>
        <Route path="/orders/:orderingEventSlug/order">
          <OrderingEventOrderPage
            cartType="default_cart_type"
            firstLocationId={this.props.data.restaurant.firstLocation?.id}
            menuItemCartOnCompleteCallback={this.menuItemCartOnCompleteCallback}
            onCheckoutButtonClick={this.onCheckoutButtonClick}
            onGoToMenuButtonClick={this.onGoToMenuButtonClick}
            page={this.state.orderingEventOrderPage}
            restaurant={this.props.data.restaurant}
          />
        </Route>
        <Route path="/orders/:orderingEventSlug">
          <OrderingEventPage
            location={this.props.data.restaurant.firstLocation}
            restaurantId={this.props.data.restaurant.id}
            restaurantName={this.props.data.restaurant.name}
            restaurantUrl={this.props.data.restaurant.popmenuUrl}
            restaurantPhotoUrl={this.props.data.restaurant.photoUrl}
          />
        </Route>
        <Route path="/events/:calendarEventSlug">
          <CalendarEventPage
            location={this.props.data.restaurant.firstLocation}
            restaurantId={this.props.data.restaurant.id}
            restaurantLogoUrl={this.props.data.restaurant.logoUrl}
            restaurantName={this.props.data.restaurant.name}
            restaurantPhotoUrl={this.props.data.restaurant.photoUrl}
            restaurantUrl={this.props.data.restaurant.popmenuUrl}
            theme={this.props.data.restaurant.theme}
          />
        </Route>
        <Route path="/feedback/:feedbackToken/thank-you">
          <FeedbackThankyouPage
            restaurantId={this.props.data.restaurant.id}
            restaurantName={this.props.data.restaurant.name}
            restaurantLogoUrl={this.props.data.restaurant.footerLogoUrl}
          />
        </Route>
        <Route path="/feedback/:feedbackToken">
          <FeedbackPage
            restaurantId={this.props.data.restaurant.id}
            restaurantName={this.props.data.restaurant.name}
            restaurantLogoUrl={this.props.data.restaurant.footerLogoUrl}
          />
        </Route>
        <Route path="/" exact>
          <CustomHomePage
            homePage={this.props.data.restaurant.homePage}
            location={this.props.data.restaurant.firstLocation}
            restaurant={this.props.data.restaurant}
          />
        </Route>
        <Route path="/*/consumer-preview">
          <Redirect to={this.props.location.hash ? `/${this.props.location.hash.replace('#', '')}` : '/'} />
        </Route>
        <Route>
          <CustomPagePage />
        </Route>
      </Switch>
    );
  }

  currentCustomPage() {
    return this.props.data.restaurant.customPages.find(customPage => customPage.url === this.props.location.pathname);
  }

  renderContent() {
    if (this.props.location.pathname === '/preview') {
      return (
        <main
          role="main"
          id="page-content"
          ref={this.mainContentRef}
          tabIndex="-1"
        >
          <PreviewPage />
        </main>
      );
    }

    const currentPage = this.currentCustomPage();
    const currentPathname = this.props.location.pathname;
    const isOLOPage = (currentPage && (currentPage.hasCateringSection || currentPage.hasOnlineOrderingSection)) || currentPathname === '/dine-in';
    const isBlogPage = /\/posts/.test(currentPathname);
    const isReservationsPage = currentPathname === '/reservations';
    const isWaitlistPage = currentPathname === '/waitlist';
    const isWaitlistLinePage = currentPathname.includes('/waitlist/line/');
    const isFeedBackPage = currentPathname.includes('feedback');
    let showNavAndFooter = currentPathname !== '/dine-in' && !isFeedBackPage;
    let showAnnouncements = currentPathname === '/';
    let showActionBar = !isOLOPage && !isReservationsPage && !isBlogPage && !isWaitlistPage && !isWaitlistLinePage && !isFeedBackPage;

    if (visualRegressionsMode) {
      if (!visualRegressionsMode.startsWith('nav_header_footer') &&
        !visualRegressionsMode.startsWith('full_page')) {
        showNavAndFooter = false;
        showAnnouncements = false;
        showActionBar = false;
      }
    }

    return (
      <React.Fragment>
        <div id="navbar-wrap">
          <BannerContainer draftMode={this.props.draftMode} restaurant={this.props.data.restaurant} showAnnouncements={showAnnouncements} />
          {showNavAndFooter && (
            <Nav
              mainContentRef={this.mainContentRef}
              onCheckoutButtonClick={this.onCheckoutButtonClick}
              onGoToMenuButtonClick={this.onGoToMenuButtonClick}
              restaurant={this.props.data.restaurant}
            />
          )}
        </div>

        <main
          role="main"
          id="page-content"
          ref={this.mainContentRef}
          tabIndex="-1"
          style={{ paddingBottom: currentPathname === '/dine-in' ? '0 !important' : 90 }}
        >
          {this.state.hasError ? <ErrorContainer /> : this.renderRoutes()}
        </main>
        {showNavAndFooter &&
          (
            <MultiSectionLazyContainer firstHiddenSection={0} height="100px">
              <Footer
                customPageId={currentPage ? currentPage.id : null}
                footerLinks={this.props.data.restaurant.footerItems}
                locationsCount={this.props.data.restaurant.locationsCount}
                restaurant={this.props.data.restaurant}
                socialHandles={this.props.data.restaurant.socialHandles}
              />
            </MultiSectionLazyContainer>
          )}
        {showActionBar && (
          <ActionBar restaurant={this.props.data.restaurant} />
        )}
      </React.Fragment>
    );
  }

  render() {
    if (!this.props.data || this.props.data.loading || !this.props.data.restaurant || !this.props.data.restaurant.theme) {
      captureSentryMessage('Performance issue: Page has no content to render. It may be an SSR/caching issue.');
      return <Loading />;
    }

    if (typeof window !== 'undefined') {
      saveAdditionalStorageKeyParams({
        lastUpdatedDate: this.props.data.restaurant.updatedAt,
        popmenuVersion: this.props.data.popmenuConfig.popmenuVersion,
      });

      prefetchPopularPagesData(
        this.props.draftMode,
        this.props.restaurantId,
        this.props.data.restaurant.customPages,
        this.props.location.pathname,
        this.props.prefetchedQueriesNumber,
      );
    }

    const { restaurant } = this.props.data;
    const { theme } = restaurant;

    // Only load the current cart for sites with an online ordering page
    const withMenuItemCart = Boolean(restaurant.onlineOrderDefaultUrl || restaurant.onlineOrderCateringUrl);

    const currentPage = this.currentCustomPage();

    return (
      <WindowSizeProvider desktop={this.props.desktop} tablet={this.props.tablet} mobile={this.props.mobile}>
        <WindowProvider initialNavbarHeight={theme.headerLogoSize === 'h_logo_lg' ? 150 : 100} isHeaderEnabled={currentPage && currentPage.isHeaderEnabled}>
          <ThemeProvider popmenuTheme={theme}>
            <SnackbarProvider>
              <PopmenuConfigContext.Provider value={this.props.data.popmenuConfig}>
                <CurrentSessionProvider restaurantId={restaurant.id} withMenuItemCart={withMenuItemCart}>
                  <MenuItemsQueryCacheProvider>
                    <RestaurantContextProvider
                      value={{
                        ...restaurant,
                        theme,
                      }}
                    >
                      <FeatureFlagsContextProvider restaurant={restaurant}>
                        <VirtualizedMenuContextProvider>
                          <ThemeContext.Provider value={theme}>
                            <React.Fragment>
                              <Helmet
                                defaultTitle={restaurant.name}
                                titleTemplate={`%s - ${restaurant.siteTitle}`}
                              >
                                {getThemeStyleTags(theme)}
                              </Helmet>

                              {this.renderContent()}

                              {!this.state.hasError && (
                                <div className="modals-wrap">
                                  <EventListenerContainer />

                                  <ConsumerModalContainer />
                                </div>
                              )}

                              <AriaElementGroup />
                              <AriaHiddenFixDialog />
                              <PageChangeHandler
                                onChange={() => this.setState({ hasError: false })}
                                restaurant={restaurant}
                              />
                            </React.Fragment>
                          </ThemeContext.Provider>
                        </VirtualizedMenuContextProvider>
                      </FeatureFlagsContextProvider>
                    </RestaurantContextProvider>
                  </MenuItemsQueryCacheProvider>
                </CurrentSessionProvider>
              </PopmenuConfigContext.Provider>
            </SnackbarProvider>
          </ThemeProvider>
        </WindowProvider>
      </WindowSizeProvider>
    );
  }
}

ConsumerContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    popmenuConfig: popmenuConfigShape,
    restaurant: PropTypes.shape({
      customBlogTitle: PropTypes.string,
      customConversionScripts: PropTypes.array,
      customDisclaimer: PropTypes.string,
      customPages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        tabName: PropTypes.string,
      })),
      footerItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
      footerLogoUrl: PropTypes.string,
      homePage: PropTypes.shape({
        heroImages: PropTypes.arrayOf(PropTypes.shape()),
        id: PropTypes.number,
        name: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.shape({
          backgroundColor: PropTypes.string,
          backgroundImageUrl: PropTypes.string,
          customHtml: PropTypes.string,
          id: PropTypes.number,
          sectionType: PropTypes.string,
        })),
        slug: PropTypes.string,
        subheading: PropTypes.string,
        tabName: PropTypes.string,
      }),
      id: PropTypes.number,
      locations: PropTypes.arrayOf(PropTypes.shape({
        city: PropTypes.string,
        displayPhone: PropTypes.string,
        enabledMenus: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          photoUrl: PropTypes.string,
          slug: PropTypes.string,
          thumbnailUrl: PropTypes.string,
        })),
        id: PropTypes.number,
        name: PropTypes.string,
        phone: PropTypes.string,
        photoUrl: PropTypes.string,
        slug: PropTypes.string,
        state: PropTypes.string,
        streetAddress: PropTypes.string,
        thumbnailUrl: PropTypes.string,
      })),
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      navItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
      photoUrl: PropTypes.string,
      restaurantUrl: PropTypes.string,
      siteTitle: PropTypes.string,
      socialHandles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        socialType: PropTypes.string,
        url: PropTypes.string,
      })),
      theme: themeShape,
    }),
  }).isRequired,
  desktop: PropTypes.bool.isRequired,
  draftMode: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  mobile: PropTypes.bool.isRequired,
  restaurantId: PropTypes.number.isRequired,
  tablet: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  connect(() => ({}), {
    openVipV2Modal,
    setConsumerActiveSection,
    setIsPreview,
    setMenuItemCartFulfillmentType,
    setMenuItemCartId,
    setMenuItemCartIsScheduled,
    setMenuItemCartLocationId,
    setMenuItemCartScheduledAt,
    setMenuItemCartType,
  }),
  withIntl,
)(ConsumerContainer);
